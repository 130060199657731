

export const boje = {
    REZERVACIJA: "#7527CB",
    NAJAM: "#6FC120",
    SERVIS: "#eb4034",
    REGISTRACIJA: "black",
    GOTOVO: "#FC9C1A"
};

export const avatarSize = 45;

export const theme_color = "#d2222b";