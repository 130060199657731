import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './Cars.css'
import { setCars, getCars , CarConverter, ServiceConverter, TypeConverter} from '../data/resourceData'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { collection, getDocs , addDoc, getDoc , deleteDoc, arrayUnion} from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import {db , storage} from '../firebase'
import { PencilFill , TrashFill, PlusCircleFill , Wrench, FileEarmarkExcelFill} from 'react-bootstrap-icons';
import { Card } from '@mui/material';
import { query, where } from "firebase/firestore"; 
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import defaultImage from '../images/default.jpg';
import { v4 as uuidv4 } from 'uuid';
import { deleteObject } from "firebase/storage"; // Import deleteObject
import { theme_color} from '../data/values';

export default function Cars() {
  var [data, setData] = React.useState([]);
  const [openNewCar, setOpenNewCar] = React.useState(false);
  const [openEditCar, setOpenEditCar] = React.useState(false);
  const [openDeleteCar, setOpenDeleteCar] = React.useState(false);
  const [carID, setCarID] = React.useState(1);
  const [name, setName] = React.useState("");
  const [reg, setReg] = React.useState("");
  const [link, setLink] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [empty, setEmpty] = React.useState(false);
  var [documentID, setDocumentID] = React.useState(false);
  var [tip, setTip] = React.useState([]);
  const [openType, setOpenType] = React.useState(false);
  const [type, setType] = React.useState('');
  const [odabraniTip, setOdabraniTip] = React.useState(false);
  const [file, setFile] = React.useState("");

  const [excelLink, setExcelLink] = React.useState("");
  const [sifraNajma, setSifraNajma] = React.useState("");
 
  const [percent, setPercent] = React.useState(0);
 
  const [gotovUpload, setGotovUpload] = React.useState(false);
  const [pokrenutUpload, setPokrenutUpload] = React.useState(false);


  const [promjena, setPromjena] = React.useState(false);

  const [activeFilters, setActiveFilters] = React.useState([]); // Održava aktivne filtere

  const [openDeleteTypeDialog, setOpenDeleteTypeDialog] = React.useState(false);
  const [typeToDelete, setTypeToDelete] = React.useState(null);
  const [openDeleteTypeConfirmation, setOpenDeleteTypeConfirmation] = React.useState(false);


  

  React.useEffect(()=> {
      getData();
  },[]);

  async function getData(){
    setIsLoading(true);
    while(data.length!=0) {
      data.pop();
    }
    
    while(tip.length!=0) {
      tip.pop();
    }
  
    const querySnapshot3 = await getDocs(collection(db, "Type").withConverter(TypeConverter));
    querySnapshot3.forEach((doc) => {
      if (doc.data()) {
        tip.push(doc.data())
      }
    });
  
    const querySnapshot = await getDocs(collection(db, "Cars").withConverter(CarConverter));
    if(querySnapshot.empty) {
      setEmpty(true);
      setIsLoading(false);
      return;
    }
    setEmpty(false);
    querySnapshot.forEach((doc) => {
      data.push(doc.data())
    });
  
    
  
    data.sort((a,b) => {
      if (a.sifraNajma < b.sifraNajma) {
        return -1;
      }
      if (a.sifraNajma > b.sifraNajma) {
        return 1;
      }
      return 0;
    });
  
    setIsLoading(false); 
  }
  

  async function setCars()
  { 

    var id = data[data.length-1].id
    
    var name = data[data.length-1].text
    var desc = data[data.length-1].desc
    var imgLink = data[data.length-1].imgLink
    var vrsta = data[data.length-1].vrsta
    var excelLink = data[data.length-1].excelLink
    var sifraNajma = data[data.length-1].sifraNajma
    
    const docRef = await addDoc(collection(db, "Cars").withConverter(CarConverter), {
        text: name,
        id: id,
        desc: desc,
        imgLink: imgLink,
        vrsta: vrsta,
        excelLink: excelLink,
        sifraNajma: sifraNajma
      });
    
      setName("");
      setReg("");
      setSifraNajma("");
      getData()
  }

  async function setTipVozila() { 
    var docid;
    const querySnapshot3 = await getDocs(collection(db, "Type").withConverter(TypeConverter));
    querySnapshot3.forEach((doc) => {
        docid = doc.id;
    });

    const upperCaseType = type.toUpperCase();  // Ensure the value is uppercase

    if (docid) {
        const ref = doc(db, "Type", docid);
    
        await updateDoc(ref, {
            tip: arrayUnion(upperCaseType)
        });
    } else {
        // Create a new document with a 'tip' array if no document exists in the "Type" collection
        const ref = collection(db, "Type");
        await addDoc(ref, {
            tip: [upperCaseType]
        });
    }

    getData();
  }

  
  const handleUpload = () => {
    if (!file) {
        alert("Please upload an image first!");
    }

    // Append a timestamp to the file name to make it unique
    const uniqueFileName = `${file.name}_${Date.now()}`;

    const storageRef = ref(storage, `/files/${uniqueFileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setPercent(percent);
        },
        (err) => console.log(err),
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                alert("Gotov upload");
                console.log(url);
                setLink(url);
                setGotovUpload(true);
            });
        }
    );
};




  const handleClickOpen = () => {
    setOpenNewCar(true);
  };

  const handleClickOpenType = () => {
    setOpenType(true);
  };

  
  

  
  

  const handleClose = () => {
    setOpenNewCar(false);
    setOpenEditCar(false);
    setOpenDeleteCar(false);
    setOpenType(false);
    
  };

  const handleCarDelete = (carId) => {
    setCarID(carId);  // Postavi ID automobila koji će se brisati
    setOpenDeleteCar(true);    // Otvori dijalog za potvrdu
  };


  const handleSubmitNewCar = () => {
    if(!name || !reg || !sifraNajma || !odabraniTip){
      alert("Molimo ispunite sva obavezna polja.");
      handleClose();
      return;
    }
    if(pokrenutUpload)
    {
      if(gotovUpload){
        let imageUrl = link || defaultImage; // Use default image if none uploaded
        let excelUrl = excelLink || "nema";   // Set Excel link to 'nema' if empty

        var id = uuidv4();

        // Push new car data including the new fields
        data.push({
          text: name,
          id: id,
          desc: reg,
          imgLink: imageUrl,
          vrsta: odabraniTip,
          excelLink: excelUrl,   // Save excel link
          sifraNajma: sifraNajma // Save Šifra Najma
        });
        setCars();
        setOpenNewCar(false);
        setLink("");       // Reset image link
        setGotovUpload(false);
        setPokrenutUpload(false);  // Reset upload state
      }
      else{
        
        alert("Molimo uploadati sliku prvo!");
        return;
      }
    }
    else{
        let imageUrl = link || defaultImage; // Use default image if none uploaded
        let excelUrl = excelLink || "nema";   // Set Excel link to 'nema' if empty

        var id = uuidv4();

        // Push new car data including the new fields
        data.push({
          text: name,
          id: id,
          desc: reg,
          imgLink: imageUrl,
          vrsta: odabraniTip,
          excelLink: excelUrl,   // Save excel link
          sifraNajma: sifraNajma // Save Šifra Najma
        });
        setCars();
        setOpenNewCar(false);
        setLink("");       // Reset image link
        setGotovUpload(false);
        setPokrenutUpload(false);  // Reset upload state
    }
      
  };


  const handleSubmitDeleteCar = async () => {
    // Prvo dohvatimo podatke o automobilu koji se briše
    const q = query(collection(db, "Cars"), where('id', '==', carID));
    let carDocId;
    let carData;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        carDocId = doc.id;
        carData = doc.data();
    });

    // 1. Brisanje slike iz Firebase Storage-a
    if (carData.imgLink && carData.imgLink !== defaultImage) {
        const imageRef = ref(storage, carData.imgLink);
        try {
            await deleteObject(imageRef);
            console.log("Slika uspješno obrisana");
        } catch (error) {
            console.error("Greška prilikom brisanja slike:", error);
        }
    }

    // 2. Brisanje automobila iz Firestore baze
    const carRef = doc(db, "Cars", carDocId);
    await deleteDoc(carRef);
    //console.log("Automobil uspješno obrisan");

    // 3. Brisanje svih povezanih događaja
    const eventQuery = query(collection(db, "EventData"), where('CarId', '==', carID));
    const eventSnapshot = await getDocs(eventQuery);

    eventSnapshot.forEach(async (doc) => {
        const eventRef = doc.ref;
        await deleteDoc(eventRef);
        //console.log("Povezani događaj uspješno obrisan");
    });

    // Nakon brisanja, zatvori dijalog i osvježi podatke
    setOpenDeleteCar(false);
    getData();  // Osvježava podatke nakon brisanja
  };

  const handleSubmitType = () => {
    setType(type.toUpperCase());  // Convert to uppercase before submission
    setTipVozila();
    setOpenType(false);
};



  const onChangeTip = (e) => {
    setOdabraniTip(e.value);
  };

  function handleChangeImage(event) {
    setFile(event.target.files[0]);
    setPokrenutUpload(true);
  }


  const reload=()=>window.location.reload();



  
  const handleOpenEditCar = (carId) => {
    //console.log("Opening car edit dialog for carId:", carId);
    
    const selectedCar = data.find(car => car.id === carId);
    if (selectedCar) {
        //console.log("Selected car:", selectedCar);
        if (!openEditCar) {
            setCarID(selectedCar.id);
            setName(selectedCar.text);
            setReg(selectedCar.desc);
            setSifraNajma(selectedCar.sifraNajma);
            setLink(selectedCar.imgLink || defaultImage);
            setOdabraniTip(selectedCar.vrsta);
            setExcelLink(selectedCar.excelLink || "nema");
            setOpenEditCar(true);
           // console.log("Dialog opened");
        }
    }
  };



  const handleSubmitEditCar = async () => {
    if (!name || !reg || !sifraNajma || !odabraniTip) {
        alert("Molimo ispunite sva obavezna polja.");
        return;
    }

    const q = query(collection(db, "Cars"), where('id', '==', carID));
    let docid;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docid = doc.id;
    });

    const ref = doc(db, "Cars", docid);
    await updateDoc(ref, {
        text: name || "Nepoznato ime",
        desc: reg || "Nema opisa",
        imgLink: link || defaultImage,
        vrsta: odabraniTip || "Nepoznata vrsta",
        excelLink: excelLink || "nema",
        sifraNajma: sifraNajma || "0000"
    });

    setOpenEditCar(false);
    getData(); // Osvježi podatke
  };

  

const handleDeleteImage = async () => {
    if (!link || link === defaultImage) {
        alert("Nema slike za brisanje!");
        return;
    }
    
    // Kreiraj referencu na datoteku u Storage-u
    const imageRef = ref(storage, link);
    
    // Pokušaj brisanje slike iz Firebase Storage-a
    try {
        await deleteObject(imageRef);
        alert("Slika uspješno obrisana!");
        
        // Nakon brisanja, postavi link na default sliku ili prazan string
        setLink("");
    } catch (error) {
        console.error("Greška prilikom brisanja slike:", error);
        alert("Greška prilikom brisanja slike.");
    }
  };

  const toggleFilter = (rentalType) => {
    if (activeFilters.includes(rentalType)) {
        setActiveFilters(activeFilters.filter(type => type !== rentalType)); // Uklanja filter ako je već aktivan
    } else {
        setActiveFilters([...activeFilters, rentalType]); // Dodaje filter ako nije aktivan
    }
  };

  const handleDeleteType = (rentalType) => {
    // Set the selected type and open confirmation dialog
    setTypeToDelete(rentalType);
    setOpenDeleteTypeConfirmation(true);
  };
  
  const handleConfirmDeleteType = async () => {
    try {
      // Step 1: Get all cars associated with this type
      const carsQuery = query(collection(db, "Cars"), where('vrsta', '==', typeToDelete));
      const carsSnapshot = await getDocs(carsQuery);
      
      // Create an array to store all car IDs associated with the selected type
      const carIds = [];
      
      // Step 2: Delete associated cars and their images
      carsSnapshot.forEach(async (doc) => {
        const carData = doc.data();
        carIds.push(carData.id); // Add the car ID to the array
  
        // Delete car image if it exists
        if (carData.imgLink && carData.imgLink !== defaultImage) {
          const imageRef = ref(storage, carData.imgLink);
          try {
            await deleteObject(imageRef);
          } catch (error) {
            console.error("Error deleting image:", error);
          }
        }
  
        // Delete the car from the Cars collection
        await deleteDoc(doc.ref);
      });
  
      // Step 3: Delete all events associated with cars of this type (matching car IDs)
      if (carIds.length > 0) {
        const eventQuery = query(collection(db, "EventData"), where('CarId', 'in', carIds));
        const eventSnapshot = await getDocs(eventQuery);
        eventSnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
      }
  
      // Step 4: Remove the type from the "Type" collection
      let docid;
      const querySnapshot = await getDocs(collection(db, "Type").withConverter(TypeConverter));
      querySnapshot.forEach((doc) => {
        docid = doc.id;
      });
  
      if (docid) {
        const ref = doc(db, "Type", docid);
        const updatedTip = tip[0].tip.filter(t => t !== typeToDelete);
  
        await updateDoc(ref, {
          tip: updatedTip
        });
      }
  
      // Close dialogs and refresh data
      setOpenDeleteTypeDialog(false);
      setOpenDeleteTypeConfirmation(false);
      getData();  // Refresh data
    } catch (error) {
      console.error("Error deleting type:", error);
    }
  };

  const [openEventsDialog, setOpenEventsDialog] = React.useState(false);
  const [selectedCarEvents, setSelectedCarEvents] = React.useState([]);
  const [selectedCarName, setSelectedCarName] = React.useState('');
  
  const handleOpenEvents = async (carId) => {
    // Find the car's name
    const car = data.find(car => car.id === carId);
    setSelectedCarName(car.text);

    // Query for events related to the car
    const eventQuery = query(collection(db, "EventData"), where('CarId', '==', carId));
    const eventSnapshot = await getDocs(eventQuery);

    // Extract event data
    const events = [];
    eventSnapshot.forEach(doc => {
        const eventData = doc.data();
        events.push({
            subject: eventData.Subject || 'No subject',
            startTime: eventData.StartTime.toDate(),
            endTime: eventData.EndTime.toDate(),
            linkRacuna: eventData.linkRacuna || null  // Add linkRacuna
        });
    });

    events.sort((a, b) => b.endTime - a.endTime);

    setSelectedCarEvents(events);
    setOpenEventsDialog(true); // Open the dialog
  };

  
  
  
  

  


  if(isLoading === true)
    {
      return 'Loading...'
    }

  if(empty === true)
  {
    
    return <div id="car_container">
        <div className="buttons_cars">
          <Button
            variant="outlined"
            color="success"
            onClick={handleClickOpen}
            sx={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme_color,
              color: '#000',
              '&:hover': {
                backgroundColor: theme_color,
                color: '#fff'
              }
            }}
          >
            DODAJ PRVU STAVKU NAJMA
          </Button>

          <Button
            variant="outlined"
            color="success"
            onClick={handleClickOpenType}
            sx={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme_color,
              color: '#000',
              '&:hover': {
                backgroundColor: theme_color,
                color: '#fff'
              }
            }}
          >
            NOVA VRSTA NAJMA
          </Button>
        </div>
        

        <Dialog open={openNewCar} onClose={handleClose}>
          <DialogTitle className="crno">DODAJ STAVKU NAJMA</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Prikazni tekst"
              fullWidth
              variant="standard"
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="regiopis"
              label="Opis"
              fullWidth
              variant="standard"
              onChange={(e) => setReg(e.target.value)}
            />

            {
            tip[0] && tip[0].tip
              ? <DropDownListComponent id="vrstaVozila" dataSource={tip[0].tip} change={onChangeTip} placeholder="Odaberi Vrstu Najma"/>
              : <p>Empty</p>
            }

            <TextField
              autoFocus
              margin="dense"
              id="sifraNajma"
              label="Šifra Najma"
              fullWidth
              variant="standard"
              onChange={(e) => setSifraNajma(e.target.value)}
            />

            <TextField
              autoFocus
              margin="dense"
              id="excelLink"
              label="Excel Link"
              fullWidth
              variant="standard"
              onChange={(e) => setExcelLink(e.target.value)}
            />
            
            <input type="file" onChange={handleChangeImage} accept="/image/*" />
            <Button onClick={handleUpload}>UPLOAD</Button>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Odustani</Button>
            <Button onClick={handleSubmitNewCar}>Potvrdi</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openType} onClose={handleClose}>
          <DialogTitle className="crno">NOVA VRSTA NAJMA</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="Tip"
              label="Vrsta najma"
              fullWidth
              variant="standard"
              onChange={(e) => setType(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Odustani</Button>
            <Button onClick={handleSubmitType}>Potvrdi</Button>
          </DialogActions>
        </Dialog>

    </div>
  }
  return ( 
    <div>

      <div id="car_container">

        <div className="buttons_cars">
        

          <Button
            variant="outlined"
            color="success"
            onClick={handleClickOpen}
            sx={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme_color,
              borderColor: theme_color,
              color: '#000',
              '&:hover': {
                backgroundColor: theme_color,
                color: '#fff'
              }
            }}
          >
            DODAJ STAVKU NAJMA
          </Button>

          <Button
            variant="outlined"
            color="success"
            onClick={handleClickOpenType}
            sx={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme_color,
              borderColor: theme_color,
              color: '#000',
              '&:hover': {
                backgroundColor: theme_color,
                color: '#fff'
              }
            }}
          >
            NOVA VRSTA NAJMA
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpenDeleteTypeDialog(true)}
            sx={{
              border: "2px solid red",
              borderColor: 'red',
              color: '#000',
              '&:hover': {
                backgroundColor: 'red',
                color: '#fff'
              }
              }}
            >
            OBRIŠI VRSTU NAJMA
          </Button>


          <div className="filter-buttons">
              {tip[0] && tip[0].tip && tip[0].tip.map((rentalType, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  color="primary"
                  className={activeFilters.includes(rentalType) ? "active" : ""}
                  onClick={() => toggleFilter(rentalType)}
                  sx={{
                    borderWidth: "2px",
                    borderStyle: "solid",
                    marginRight: 1,
                    marginBottom: 1,
                    borderColor: theme_color,
                    '&:hover': {
                      backgroundColor: theme_color,
                      color: '#fff'
                    }
                  }}
                >
                  {rentalType}
                </Button>
              ))}
          </div>


        </div>

          <Dialog open={openType} onClose={handleClose}>
          <DialogTitle className="crno">NOVA VRSTA NAJMA</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="Tip"
              label="Vrsta najma"
              fullWidth
              variant="standard"
              onChange={(e) => setType(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Odustani</Button>
            <Button onClick={handleSubmitType}>Potvrdi</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openNewCar} onClose={handleClose}>
          <DialogTitle className="crno">DODAJ STAVKU NAJMA</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Prikazni tekst"
              fullWidth
              variant="standard"
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="regiopis"
              label="Opis"
              fullWidth
              variant="standard"
              onChange={(e) => setReg(e.target.value)}
            />

            {
            tip[0] && tip[0].tip
              ? <DropDownListComponent id="vrstaVozila" dataSource={tip[0].tip} change={onChangeTip} placeholder="Odaberi Vrstu Najma"/>
              : <p>Empty</p>
            }

            <TextField
              autoFocus
              margin="dense"
              id="sifraNajma"
              label="Šifra Najma"
              fullWidth
              variant="standard"
              onChange={(e) => setSifraNajma(e.target.value)}
            />

            <TextField
              autoFocus
              margin="dense"
              id="excelLink"
              label="Excel Link"
              fullWidth
              variant="standard"
              onChange={(e) => setExcelLink(e.target.value)}
            />
            
            <input type="file" onChange={handleChangeImage} accept="/image/*" />
            <Button onClick={handleUpload}>UPLOAD</Button>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Odustani</Button>
            <Button onClick={handleSubmitNewCar}>Potvrdi</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openEditCar} onClose={handleClose}>
          <DialogTitle className="crno">UREDI STAVKU NAJMA</DialogTitle>
          <DialogContent>
              <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Prikazni tekst"
                  value={name}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setName(e.target.value)}
              />
              <TextField
                  autoFocus
                  margin="dense"
                  id="regiopis"
                  label="Opis"
                  value={reg}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setReg(e.target.value)}
              />
              {tip[0] && tip[0].tip ? (
                  <DropDownListComponent
                      id="vrstaVozila"
                      dataSource={tip[0].tip}
                      value={odabraniTip}
                      change={onChangeTip}
                      placeholder="Odaberi Vrstu Najma"
                  />
              ) : <p>Empty</p>}

              <TextField
                  autoFocus
                  margin="dense"
                  id="sifraNajma"
                  label="Šifra Najma"
                  value={sifraNajma}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setSifraNajma(e.target.value)}
              />

              <TextField
                  autoFocus
                  margin="dense"
                  id="excelLink"
                  label="Excel Link"
                  value={excelLink}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setExcelLink(e.target.value)}
              />

              <input type="file" onChange={handleChangeImage} accept="image/*" />
              <Button onClick={handleUpload}>UPLOAD</Button>
              <Button onClick={handleDeleteImage} color="secondary">Ukloni sliku</Button>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Odustani</Button>
              <Button onClick={handleSubmitEditCar}>Spremi promjene</Button>
          </DialogActions>
        </Dialog>


        <Dialog open={openDeleteCar} onClose={handleClose}>
          <DialogTitle className="crno">POTVRDA BRISANJA</DialogTitle>
            <DialogContent>
                <DialogContentText>Jeste li sigurni da želite obrisati ovaj automobil i sve povezane događaje?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Odustani</Button>
                <Button onClick={handleSubmitDeleteCar} color="secondary">Obriši</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openDeleteTypeDialog} onClose={() => setOpenDeleteTypeDialog(false)}>
          <DialogTitle className="crno">Obriši Vrstu Najma</DialogTitle>
          <DialogContent>
            {tip[0] && tip[0].tip ? (
              <List>
                {tip[0].tip.map((rentalType, index) => (
                  <ListItem key={index} button onClick={() => setTypeToDelete(rentalType)}>
                    <ListItemText primary={rentalType} />
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeleteType(rentalType)}
                    >
                      OBRIŠI
                    </Button>
                  </ListItem>
                ))}
              </List>
            ) : <p>Empty</p>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteTypeDialog(false)}>Zatvori</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeleteTypeConfirmation} onClose={() => setOpenDeleteTypeConfirmation(false)}>
          <DialogTitle className="crno">Potvrda Brisanja</DialogTitle>
          <DialogContent>Jeste li sigurni da želite obrisati vrstu najma i sve povezane stavke? Obrisat će se svi najmovi s tim tipom.</DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteTypeConfirmation(false)}>Odustani</Button>
            <Button onClick={handleConfirmDeleteType} color="secondary">Obriši</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openEventsDialog} onClose={() => setOpenEventsDialog(false)}>
          <DialogTitle className="crno">Izvještaj za "{selectedCarName}"</DialogTitle>
          <DialogContent>
              {selectedCarEvents.length > 0 ? (
                  <List>
                      {selectedCarEvents.map((event, index) => (
                          <ListItem key={index}>
                              <ListItemText 
                                  primary={
                                      <>
                                          <strong>Klijent: </strong>{event.subject}
                                      </>
                                  }
                                  secondary={
                                      <> 
                                          {`${event.startTime.toLocaleDateString()} - ${event.endTime.toLocaleDateString()}`}
                                          <br />
                                          {event.linkRacuna && event.linkRacuna !== "nema" ? (
                                              <a href={event.linkRacuna} target="_blank" rel="noopener noreferrer">
                                                  Stisni za račun
                                              </a>
                                          ) : (
                                              <span>Nema računa</span>
                                          )}
                                      </>
                                  }
                              />
                          </ListItem>
                      ))}
                  </List>
              ) : (
                  <DialogContentText>Nema rezervacija za najam.</DialogContentText>
              )}
          </DialogContent>
          <DialogActions>
              <Button onClick={() => setOpenEventsDialog(false)}>Zatvori</Button>
          </DialogActions>
      </Dialog>







      </div>

      <div>
      <div className="cards">
        {data
            .filter(car => activeFilters.length === 0 || activeFilters.includes(car.vrsta)) // Filtrira kartice
            .sort((a, b) => {
                if (a.sifraNajma < b.sifraNajma) {
                    return -1;
                }
                if (a.sifraNajma > b.sifraNajma) {
                    return 1;
                }
                return 0;
            })
            .map(car => (
                <div className="card" key={car.id}>
                    <img src={car.imgLink} className="card__image" alt={car.text} />
                    <div className="card__content">
                        <h3 className="card__title">{car.text}</h3>
                        <p className="card__description">
                            <span className="bold-label">Opis:</span> {car.desc}
                            <br />
                            <span className="bold-label">Vrsta:</span> {car.vrsta}
                            <br />
                            <span className="bold-label">Šifra:</span> {car.sifraNajma}
                        </p>
                    </div>
                    <div className="card__buttons">
                    <button className="button" onClick={() => handleOpenEvents(car.id)}>
                        Izvještaj
                    </button>
                        <button className="button" onClick={() => handleOpenEditCar(car.id)}><PencilFill /></button>
                        <button className="button" onClick={() =>handleCarDelete(car.id)}><TrashFill /></button>
                        {car.excelLink && car.excelLink !== "nema" && (
                            <a
                                href={car.excelLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="button"
                            >
                                <FileEarmarkExcelFill />
                            </a>
                        )}
                    </div>
                </div>
            ))
            }
        </div>


      </div>

    </div>
  )}
    

