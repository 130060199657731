import React, { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import logo from '../images/logopng.png';
import './Navbar.css'; // Assuming this is where your CSS file is located

export default function NavigationBar() {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
      console.log('You are logged out');
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <nav className="navbar">
      
        <a href="/" className="logo-link">
          <img src={logo} className="logo" alt="STEFMARK LOGO" />
        </a>
        <div className="nav-links-wrapper">
          <div className="nav-links">
            <a href="/" className="nav-button">TIMELINE</a>
            <a href="/najmovi" className="nav-button">UREDI NAJMOVE</a>
            <a href="/dnevniplan" className="nav-button">DNEVNI PLAN</a>
            
            <a href="/partneri" className="nav-button">PARTNERI</a>
          </div>
        </div>
        <button type="button" onClick={handleLogout} className="nav-button logout-button">
          ODJAVA
        </button>
      
    </nav>
  );
}
