import React, { useState } from "react";
import "./Partneri.css";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {PartneriConverter} from '../data/resourceData'
import { collection, getDocs , addDoc, getDoc , deleteDoc} from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import {db} from '../firebase'
import { PencilFill , TrashFill, PlusCircleFill , Wrench} from 'react-bootstrap-icons';
import { query, where } from "firebase/firestore"; 
import { reload } from "firebase/auth";
import { theme_color } from "../data/values";

function Partneri() {
  var [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [ime, setIme] = React.useState(false);
  const [djelatnost, setDjelatnost] = React.useState(false);
  const [broj_mobitela, setBrojMobitela] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [rowID, setRowID] = React.useState(1);
  const [empty, setEmpty] = React.useState(false);
  const [change, setChange] = React.useState(false);

  React.useEffect(()=> {
    getData();
  },[]);

  async function getData(){
    setIsLoading(true);
    while(data.length!=0)
    {
      data.pop();
    }
    const querySnapshot = await getDocs(collection(db, "Partneri").withConverter(PartneriConverter));
    //console.log(querySnapshot.empty)
    if(querySnapshot.empty)
    {
      setIsLoading(false);
      setEmpty(true);
      return;
    }
    setEmpty(false);
    querySnapshot.forEach((doc) => {
            data.push(doc.data())
      });
      setIsLoading(false); 

      data.sort((a,b) =>{
        return a.id - b.id;
      });
  }

  async function setPartneri()
  { 
    //console.log(data)
    var id = data[data.length-1].id
    //console.log(id)
    var ime = data[data.length-1].ime
    var djelatnost = data[data.length-1].djelatnost
    var broj_mobitela = data[data.length-1].broj_mobitela

    const docRef = await addDoc(collection(db, "Partneri").withConverter(PartneriConverter), {
        ime: ime,
        id: id,
        djelatnost: djelatnost,
        broj_mobitela: broj_mobitela
      });
      getData();
    //reload();
  }

  async function editPartneri()
  {  
    const q = query(collection(db,"Partneri"), where('id', '==', rowID));
    var docid;
    const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        docid = doc.id;
    });

    const ref = doc(db, "Partneri" , docid);

    await updateDoc(ref, {
      ime: data[rowID-1].ime,
      id: data[rowID-1].id,
      djelatnost: data[rowID-1].djelatnost,
      broj_mobitela: data[rowID-1].broj_mobitela
    }
    );
  }

  async function deletePartneri()
  {  
    const q = query(collection(db,"Partneri"), where('id', '==', rowID));
      var docid;
      const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          docid = doc.id;
      });

    const ref = doc(db, "Partneri" , docid);
    await deleteDoc(ref);


    var id = rowID + 1;

    while(id <= data[data.length-1].id)
    {
      console.log(id)
      const q = query(collection(db,"Partneri"), where('id', '==', id));
      var docid;
      const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          docid = doc.id;
      });

      const ref = doc(db, "Partneri" , docid);
      var newID = id-1;
      await updateDoc(ref, {
        ime: data[id-1].ime,
        id: newID,
        djelatnost: data[id-1].djelatnost,
        broj_mobitela: data[id-1].broj_mobitela
      }
      );
      id++;
    }
    getData();
    //reload();
  }

  const handleRowEdit = rowID => () => {
    setRowID(rowID);
    setOpen2(true);   
  };


  const handleRowDel = rowID => () => {
    setRowID(rowID);
    setOpen3(true);   
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
  };

  const handleSubmit = () => {
    data.sort((a,b) =>{
      return a.id - b.id;
    });
    var id
    if(empty)
      id = 1
    else
      id = data.length + 1
    data.push({ime: ime , id: id, djelatnost: djelatnost, broj_mobitela: broj_mobitela})
    setPartneri();
    console.log(data);
    setOpen(false);
  };

  const handleNameChange = (e) => {
    setIme(e.target.value || data[rowID-1].ime);
    setDjelatnost(data[rowID-1].djelatnost);
    setBrojMobitela(data[rowID-1].broj_mobitela);
    setChange(true);
  };

  const handleRegChange = (e) => {
    setIme(data[rowID-1].ime);
    setDjelatnost(e.target.value || data[rowID-1].djelatnost);
    setBrojMobitela(data[rowID-1].broj_mobitela);
    setChange(true);
  };

  const handleLinkChange = (e) => {
    setIme(data[rowID-1].ime);
    setDjelatnost(data[rowID-1].djelatnost);
    setBrojMobitela(e.target.value || data[rowID-1].broj_mobitela);
    setChange(true);
  };

  const handleSubmit2 = () => {
    if(change === true)
      data[rowID-1]=({ime: ime , id: rowID, djelatnost: djelatnost, broj_mobitela: broj_mobitela});
    editPartneri();
    setChange(false);
    setOpen2(false);
  };

  const handleSubmit3 = () => {
    deletePartneri();
    setOpen3(false);
  };

  if(isLoading === true)
    {
      return 'Loading...'
    }
  if(empty === true)
    {
      return <div className="table-wrapper">

      <Button variant="outlined" sx={{
              borderColor: theme_color,
              color: '#000',
              '&:hover': {
                backgroundColor: theme_color,
                color: '#fff'
              }
            }} color="success" onClick={handleClickOpen}>
                  Prvi Partner
      </Button>

    <Dialog open={open} onClose={handleClose}>
          <DialogTitle className="naslov">Novi Partner</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="ime"
              label="Ime"
              fullWidth
              variant="standard"
              onChange={(e) => setIme(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="djelatnost"
              label="Djelatnost"
              fullWidth
              variant="standard"
              onChange={(e) => setDjelatnost(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="brojmobitela"
              label="Broj Mobitela"
              fullWidth
              variant="standard"
              onChange={(e) => setBrojMobitela(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Odustani</Button>
            <Button onClick={handleSubmit}>Potvrdi</Button>
          </DialogActions>
        </Dialog>

      </div>

    }

  return (
    <div>
      
    <div className="table-wrapper">
      <div className="gumbi">
        <Button variant="outlined" sx={{
                borderColor: theme_color,
                color: '#000',
                '&:hover': {
                  backgroundColor: theme_color,
                  color: '#fff'
                }
              }} color="success" onClick={handleClickOpen}>
              Novi Partner
      </Button>
      </div>
    

    <Dialog open={open} onClose={handleClose}>
          <DialogTitle className="naslov">Novi Partner</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="ime"
              label="Ime"
              fullWidth
              variant="standard"
              onChange={(e) => setIme(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="djelatnost"
              label="Djelatnost"
              fullWidth
              variant="standard"
              onChange={(e) => setDjelatnost(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="brojmobitela"
              label="Broj Mobitela"
              fullWidth
              variant="standard"
              onChange={(e) => setBrojMobitela(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Odustani</Button>
            <Button onClick={handleSubmit}>Potvrdi</Button>
          </DialogActions>
        </Dialog>


        <Dialog open={open2} onClose={handleClose}>
          <DialogTitle className="naslov">Uredi Partnera</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="ime"
              label="Ime"
              fullWidth
              defaultValue={data[rowID-1].ime}
              variant="standard"
              onChange={handleNameChange}
            />
            <TextField
              autoFocus
              margin="dense"
              id="djelatnost"
              label="Djelatnost"
              fullWidth
              defaultValue={data[rowID-1].djelatnost}
              variant="standard"
              onChange={handleRegChange}
            />
            <TextField
              autoFocus
              margin="dense"
              id="brojmobitela"
              label="Broj Mobitela"
              fullWidth
              defaultValue={data[rowID-1].broj_mobitela}
              variant="standard"
              onChange={handleLinkChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Odustani</Button>
            <Button onClick={handleSubmit2}>Potvrdi</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={open3} onClose={handleClose}>
          <DialogTitle className="naslov">Jeste sigurni da želite obrisati tog partnera?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Odustani</Button>
            <Button onClick={handleSubmit3}>IZBRIŠI</Button>
          </DialogActions>
        </Dialog>




      <table className="fl-table">
        <thead>
          <tr>
            <th className="akcije"></th>
            <th>ID</th>
            <th>Ime</th>
            <th>Djelatnost</th>
            <th>Broj Mobitela</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td>
                <Button onClick={handleRowEdit(item.id)}><PencilFill/></Button>
                <Button onClick={handleRowDel(item.id)}><TrashFill/></Button>
              </td>
              <td>{item.id}</td>
              <td>{item.ime}</td>
              <td>{item.djelatnost}</td>
              <td>{item.broj_mobitela}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
}

export default Partneri;